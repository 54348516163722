import React from 'react'
import Layout from '../components/Layout'

export default function FourOhFourPage() {
  return (
    <Layout>
      <p>Hey - That page doesn't exist!!!</p>
    </Layout>
  )
}
